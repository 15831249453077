$.extend({
    "typeSwiperOption":function(obj){
        new Swiper(obj, {
            lazy: {
                loadPrevNext: true,
            },
            loop: false,
            autoplay: {
                pauseOnMouseEnter: true,
            },
            slidesPerView:3,
            spaceBetween:10,
            grabCursor : true,  //手势图标
            mousewheel: true, //鼠标滑轮控制
            uniqueNavElements: true, //导航元素是否唯一
            roundLengths : false,  //宽度四舍五入  防止模糊
            watchOverflow: true, // 项目不够时，隐藏控制组件
            observer:true,
            observeParents:true,
            observeSlideChildren:true,
            preventClicksPropagation: false,
            navigation: {
                prevEl: '.btn-prev',
                nextEl: '.btn-next'
            },
            breakpoints: {
                640: {
                    slidesPerView:3,
                    spaceBetween:10,
                },
                768: {
                    slidesPerView:6,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView:8,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView:10,
                    spaceBetween: 10,
                },
                1920: {
                    slidesPerView:12,
                    spaceBetween: 10,
                },
            }


        });
    },
});

setTimeout(function () {

    var homeHotSwiper = new Swiper("#homeHotSwiper", {
        lazy: {
            loadPrevNext: true,
        },
        loop: true,
        /*autoplay: {
            pauseOnMouseEnter: true,
        },*/
        spaceBetween: 10,
        observer:true,
        observeParents:true,
        observeSlideChildren:true,
        pagination: {
            el: "#homeHotSwiper-pagination",
            // dynamicBullets: true,
        },
    });

    $(".type-swiper-item").each(function(index){
        $.typeSwiperOption(this);
    });

},60);











